export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "addCluster": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Cluster Installation Plan"])},
        "createResource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Resource Package"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "requiresName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please input name."])},
        "conflict": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["conflict with existing one ", _interpolate(_named("name")), "."])},
        "goToResourcePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is going to open Resource package management page in a new window, do you confirm?"])}
      },
      "zh": {
        "addCluster": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加集群安装计划"])},
        "createResource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加资源包"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名称"])},
        "requiresName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请填写名称"])},
        "conflict": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["与已有的重复 ", _interpolate(_named("name"))])},
        "goToResourcePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此操作将要在新窗口打开资源包加载页面，完成设置后，您可以切换窗口回到当前页面，是否继续？"])}
      }
    }
  })
}
