export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "clusters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cluster Management"])},
        "clusterList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clusters List"])},
        "addCluster": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Cluster Installation Plan"])},
        "confirmToDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you confirm to delete the cluster info in KuboardSpray? Cluster itself will not be impacted."])},
        "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重命名"])}
      },
      "zh": {
        "clusters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["集群管理"])},
        "clusterList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["集群列表"])},
        "addCluster": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加集群安装计划"])},
        "confirmToDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是否删除此集群在 KuboardSpray 的信息？（该集群本身将继续运行不受影响。）"])},
        "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重命名"])}
      }
    }
  })
}
