export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "conflict": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["conflict with existing one ", _interpolate(_named("name")), "."])},
        "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rename"])},
        "renameCluster": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Rename cluster ", _interpolate(_named("name")), " to"])},
        "input_newName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please input new cluster name here."])}
      },
      "zh": {
        "conflict": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["与已有的重复 ", _interpolate(_named("name"))])},
        "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重命名"])},
        "renameCluster": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["将集群 ", _interpolate(_named("name")), " 重命名为"])},
        "input_newName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入集群的新名称"])}
      }
    }
  })
}
